/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";
@import "./admin-portal/common/styles/calendar.less";
@import "./admin-portal/common/styles/modal.less";
@import "./admin-portal/common/styles/icon.less";
@import '@mt-ng2/styles/styles.less';

@hover: #030912;

@primary:  #0e2954;
@secondary:  #8dadd6;

@lightBackground: #fafdff;
@fontColor: #171926;

@sidebarLinkFontColor: #f4f9fb;
@sidebarLiHeaderFontColor: #b1cff5;

@btnApproveColor: #00a65a;
@btnApproveColorActive: #008d4c;

@tableHeader: lighten(#9bbde9, 5%);
@tableStripedAltBackgroundColor: lighten(@secondary, 25%);

@dueSoon: #ad7b25;
@onTime: @fontColor;
@overDue: #dc3545;
@completed: darkgreen;

body {
    color: @fontColor;
}

.app-root-wrapper {
    background-color: #ecf0f5;
    min-height: 100vh;
}

// Sidebar
.skin-blue .sidebar a, .skin-blue .sidebar a {
    font-weight: bold;
}

.skin-blue .sidebar-menu > li:hover > app-nav-menu-item > a:hover,
.skin-blue .sidebar-menu > li > .treeview-menu li:hover {
    background-color: @hover;
}

h2 {
    font-weight: 400;
}

.btn-primary-inverse, .btn-primary-inverse:active, .btn-primary-inverse:visited, .btn-primary-inverse:focus {
    background-color: transparent;
    border-color: inherit;
}

.btn-fab-md {
    color: white !important;
}

.btn-approve,
.btn-approve:active,
.btn-approve:visited,
.btn-approve:focus {
    background-color: @btnApproveColor;
    border-color: @btnApproveColor;
    color: #fff;
}

.btn-approve:active:focus,
.btn-approve:hover {
    background-color: @btnApproveColorActive;
    border-color: @btnApproveColorActive;
    color: #fff;
}

meta-item-managed-list {
    .btn-success {
        border-radius: 0;
        box-shadow: none;
        border-width: 1px;
    }
}

.dropdown-toggle {
    margin-top: 0px;
}

.align-right {
    text-align: right;
}

.mb-sm {
    margin-bottom: 1rem;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.font-italic {
    font-style: italic;
}

.w-100 {
    width: 100%;
}

.icon-primary {
    color: @lightPrimary;
    &:hover {
        color: @primary;
    }
}

.icon-action {
    cursor: pointer;
    padding: 2px;
    border: #e7e7e7 solid 1px;
    border-radius: 10%;
    &:hover {
        background-color: #e7e7e7;
        border-color: #ddd;
    }
}

.audit-log {
    text-align: initial;
}

.miles-card, .miles-form {
    color: @fontColor;
    font-size: 15px;
    b {
        color: darken(@fontColor, 10%);
    }
}

.swal2-content {
    color: @fontColor !important;
}

.export-button .btn {
    color: @fontColor;
    background-color: @tableHeader !important;
}

.btn-transparent:hover {
    background-color: #97c9f7;
    border-color: #97c9f7;
}

.scrollable-menu > li > a {
    color: @fontColor;
}

// Table Styling
.table > thead > tr th {
    border-bottom: 2px solid lighten(@primary, 20%);
}
table thead {
    background-color: @tableHeader;
}
.table > thead > tr th,
.table > tbody > tr th,
.table > tfoot > tr th,
.table > thead > tr td,
.table > tbody > tr td,
.table > tfoot > tr td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid lighten(@primary, 20%);
}
.table-striped > tbody > tr:hover td {
    background-color: lighten(@secondary, 15%);
}
.table-striped > tbody > tr:last-child td,
.table-striped > tbody > tr:last-child th {
    border-bottom: 1px solid lighten(@primary, 20%) !important;
}

tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}
.table {
    a {
        color: #002766
    }
}
