.icon-size-1 {
    font-size: 1rem;
}

.icon-size-2 {
    font-size: 2rem;
}

.icon-delete {
    color: #dc3545;
}
