.fc .fc-col-header-cell-cushion, a.fc-daygrid-day-number {
    color: #1a252f !important;
}

.fc table td {
    border: thin solid #ddd;
}
.fc .fc-daygrid-day-frame {
    background-color: white;
}

.fc .fc-timegrid-col {
    background-color: white;
}

.fc .fc-list-day th {
    background-color: #cbcccc !important;
}

.fc .fc-list-event {
    background-color: white;
}

// prevent view buttons from stacking
@media screen  and (max-width: 600px){
    .fc-header-toolbar div.fc-toolbar-chunk:first-child {
        width: 50%;
    }

    .fc-header-toolbar div.fc-toolbar-chunk:last-child {
        width: 50%;
    }
    .fc-header-toolbar div.fc-toolbar-chunk:last-child .btn-group {
        float:right;
    }
}

// put date range in list view in 1 row
@media screen and (min-width: 480px) and (max-width: 600px){
    .fc-header-toolbar div.fc-toolbar-chunk:first-child {
        width: 70%;
    }
    .fc-header-toolbar div.fc-toolbar-chunk:last-child {
        width: 30% ;
    }
}

.black.fc-h-event .fc-event-main, .black.fc-event .fc-event-main {
    color: black !important;
}

.white.fc-h-event .fc-event-main, .white.fc-event .fc-event-main {
    color: white !important;
}
